const SvgLogoSm = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="173"
    height="207"
    fill="none"
    viewBox="0 0 173 207"
  >
    <path
      fill="#EAB941"
      d="M56.785 56.126 0 163.832l86 43.088 42.924-20.891-73.771-39.17 24.155-46.666z"
    ></path>
    <path
      fill="#EAB941"
      d="M86.022 0 62.683 46.013l53.533 99.872-17.3 8.487 45.046 24.808 29.039-15.668z"
    ></path>
    <path
      fill="#EAB941"
      d="m85.993 110.64-15.831 30.344 15.831 7.508 14.312-7.181z"
    ></path>
  </svg>
);

export default SvgLogoSm;
