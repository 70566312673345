interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode,
  haptic?: boolean,
}

export default function Button(props: ButtonProps) {
  const { onClick, children, haptic = true, ...rest } = props;
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(event);
    if (haptic) {
      window.Telegram.WebApp.HapticFeedback.selectionChanged();
    }
  }

  return (
    <button onClick={clickHandler} {...rest}>
      { children }
    </button>
  )
}