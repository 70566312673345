import { ToastContainer } from 'react-toastify';
import MainApp from '@/components/MainApp';

import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/pagination';
import '@/scss/App.scss';

declare global {
  interface Window {
    Telegram: any
  }
}

function App() {
  return (
    <>
      <ToastContainer />
      <MainApp />
    </>
  )
}

export default App
