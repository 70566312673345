import SvgBack from "@/assets/svg/SvgBack";
import { RootState } from "@/features/store";
import { toggleIntro } from "@/features/store/slices/userSlice";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Intro() {
  const dispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => (state.user.intro));
  const slider = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  useEffect(() => {
    slider?.current?.slideTo(activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    if (isVisible) {
      setActiveIndex(0);
      setTotalSlides(slider?.current?.slides?.length || 0);
    }
  }, [isVisible, slider]);

  const prevHandler = () => {
    if (activeIndex > 0) {
      setActiveIndex((current) => (current - 1));
    }
  }

  const nextHandler = () => {
    if (activeIndex < totalSlides - 1) {
      setActiveIndex((current) => (current + 1))
    } else {
      dispatch(toggleIntro(false));
    }
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="intro"
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="intro__content">
            <Swiper
              onSwiper={(swiper) => (slider.current = swiper)}
              slidesPerView={1}
              className="intro__slider"
              modules={[Pagination]}
              pagination={{ clickable: true }}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
              <SwiperSlide>
                <div className="intro__media">
                  <video src="/media/intro/1.mp4" muted autoPlay playsInline />
                </div>
                <div className="intro__box">
                  <div className="intro__title">
                    <span>Afrimex</span>
                    <br />
                    Speakers
                  </div>
                  <div className="intro__text">
                    Complete the training, accomplish the tasks, and receive $500 on the Afrimex Gold platform. Limited-time offer.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="intro__media">
                  <video src="/media/intro/1.mp4" muted autoPlay playsInline />
                </div>
                <div className="intro__box">
                  <div className="intro__title">
                    <span>Gold</span>
                    <br />
                    Mining
                  </div>
                  <div className="intro__text">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci quia quam neque ipsum doloremque?
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="intro__media">
                  <video src="/media/intro/1.mp4" muted autoPlay playsInline />
                </div>
                <div className="intro__box">
                  <div className="intro__title">
                    <span>Team</span>
                    <br />
                    Building
                  </div>
                  <div className="intro__text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab optio ratione voluptatem ipsam et ut aliquid, sit itaque repudiandae qui saepe impedit.
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="intro__buttons">
              {activeIndex > 0 && (
                <button className="back-button --lg" onClick={prevHandler}>
                  <SvgBack />
                </button>
              )}
              <button className="default-button --lg" onClick={nextHandler}>
                { activeIndex < totalSlides - 1 ? 'Continue' : 'Get started' }
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

}