import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: null,
  intro: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    toggleIntro: (state, action) => {
      state.intro = action.payload;
    },
    reset: () => initialState,
  }
});

export const {
  setLanguage,
  toggleIntro,
  reset,
} = userSlice.actions;
export default userSlice.reducer;
