const SvgRussia = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    fill="none"
    viewBox="0 0 24 18"
  >
    <g clipPath="url(#clip0_4269_123)">
      <rect
        width="23.154"
        height="16.538"
        x="0.513"
        y="0.782"
        fill="#1A47B8"
        rx="3.308"
      ></rect>
      <path
        fill="#F93939"
        fillRule="evenodd"
        d="M.513 11.808h23.154v5.512H.513z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.513.782h23.154v5.513H.513z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_4269_123">
        <rect
          width="23.154"
          height="16.538"
          x="0.513"
          y="0.782"
          fill="#fff"
          rx="3.308"
        ></rect>
      </clipPath>
    </defs>
  </svg>
);

export default SvgRussia;
