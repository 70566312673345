import AppHolder from '@/components/AppHolder';
import CASH_IMAGE from '@/assets/img/cash.png';
import Resizable from '@/components/Resizable';
import OnBoarding, { OnBoardingProps } from '@/components/OnBoarding';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import SvgBook from '@/assets/svg/SvgBook';
import { APP_ROUTES } from '@/features/constants';

export default function Home() {
  return (
    <AppHolder>
      <OnBoarding steps={firstSteps} id="first-steps-1" />
      <div className="profile" id="profile">
        <div className="profile__info">
          <Resizable className="profile__info--name">
            👋 Hi Jhonny12
          </Resizable>
          <Resizable className="profile__info--progress">
            Your progress: <span>0%</span>
          </Resizable>
        </div>
        <div className="profile__gift">
          <div className="profile__gift--col">
            <div className="profile__gift--title">
              Your gift
            </div>
            <div className="profile__gift--value">
              $0
            </div>
          </div>
          <div className="profile__gift--col">
            <img src={CASH_IMAGE} alt="" />
          </div>
        </div>
      </div>
      <div className="progress">
        <div className="progress__line" style={{ width: '0%' }} />
      </div>
      <Swiper
        className="home-slider"
        modules={[Pagination]}
        slidesPerView={'auto'}
        centeredSlides
        pagination
        spaceBetween={20}
        loop
      >
        <SwiperSlide id="tasks">
          <div className="home-slider__title">
            Get money!
          </div>
          <div className="home-slider__award">
            +250$
          </div>
          <div className="home-slider__text">
            Complete the training
            <br />
            and earn rewards! $250 from each course
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="home-slider__title">
            Get money!
          </div>
          <div className="home-slider__award">
            +250$
          </div>
          <div className="home-slider__text">
            Complete the training
            <br />
            and earn rewards! $250 from each course
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="home-slider__title">
            Get money!
          </div>
          <div className="home-slider__award">
            +250$
          </div>
          <div className="home-slider__text">
            Complete the training
            <br />
            and earn rewards! $250 from each course
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="home-slider__title">
            Get money!
          </div>
          <div className="home-slider__award">
            +250$
          </div>
          <div className="home-slider__text">
            Complete the training
            <br />
            and earn rewards! $250 from each course
          </div>
        </SwiperSlide>
      </Swiper>
      <hr />
      Init data:
      <pre>
        { window.Telegram.WebApp.initData || 'no init data' }
      </pre>
      <div className="courses">
        <div className="courses__title">
          Let’s go your courses!
        </div>
        <div className="courses__list">
          <div className="courses__box" id="first-course">
            <div className="courses__box--number">
              0/13
            </div>
            <div className="courses__box--author">
              <div className="courses__box--avatar">
                <img src="/img/preview/author-1.png" alt="" />
              </div>
              <NavLink to="">
                Kamil Mundo
              </NavLink>
            </div>
            <div className="courses__box--title">
              What is Afrimex?
            </div>
            <div className="courses__box--shortdesc">
              About the company and oportunity
            </div>

            <NavLink to={`${APP_ROUTES.COURSE.INDEX}/test-course`} className="default-button">
              <SvgBook />
              <span>
                Start learning
              </span>
            </NavLink>

            <img src="/img/preview/task-1.png" alt="" className="courses__box--icon" />
            <img src="/img/preview/task-1_bg.png" alt="" className="courses__box--background" />
          </div>

          <div className="courses__box --disabled">
            <div className="courses__box--number">
              0/7
            </div>
            <div className="courses__box--author">
              <div className="courses__box--avatar">
                <img src="/img/preview/author-2.png" alt="" />
              </div>
              <NavLink to="">
                Kamil Mundo
              </NavLink>
            </div>
            <div className="courses__box--title">
              What is Afrimex?
            </div>
            <div className="courses__box--shortdesc">
              About the company and oportunity
            </div>

            <NavLink to="" className="default-button">
              <span>
                Not Available
              </span>
            </NavLink>

            <img src="/img/preview/task-2.png" alt="" className="courses__box--icon" />
          </div>

          <div className="courses__box --disabled">
            <div className="courses__box--number">
              0/7
            </div>
            <div className="courses__box--author">
              <div className="courses__box--avatar">
                <img src="/img/preview/author-2.png" alt="" />
              </div>
              <NavLink to="">
                Kamil Mundo
              </NavLink>
            </div>
            <div className="courses__box--title">
              What is Afrimex?
            </div>
            <div className="courses__box--shortdesc">
              About the company and oportunity
            </div>

            <NavLink to="" className="default-button">
              <span>
                Not Available
              </span>
            </NavLink>

            <img src="/img/preview/task-2.png" alt="" className="courses__box--icon" />
          </div>

          <div className="courses__box --disabled">
            <div className="courses__box--number">
              0/7
            </div>
            <div className="courses__box--author">
              <div className="courses__box--avatar">
                <img src="/img/preview/author-2.png" alt="" />
              </div>
              <NavLink to="">
                Kamil Mundo
              </NavLink>
            </div>
            <div className="courses__box--title">
              What is Afrimex?
            </div>
            <div className="courses__box--shortdesc">
              About the company and oportunity
            </div>

            <NavLink to="" className="default-button">
              <span>
                Not Available
              </span>
            </NavLink>

            <img src="/img/preview/task-2.png" alt="" className="courses__box--icon" />
          </div>

          <div className="courses__box --disabled">
            <div className="courses__box--number">
              0/7
            </div>
            <div className="courses__box--author">
              <div className="courses__box--avatar">
                <img src="/img/preview/author-2.png" alt="" />
              </div>
              <NavLink to="">
                Kamil Mundo
              </NavLink>
            </div>
            <div className="courses__box--title">
              What is Afrimex?
            </div>
            <div className="courses__box--shortdesc">
              About the company and oportunity
            </div>

            <NavLink to="" className="default-button">
              <span>
                Not Available
              </span>
            </NavLink>

            <img src="/img/preview/task-2.png" alt="" className="courses__box--icon" />
          </div>
        </div>
      </div>
    </AppHolder>
  );
}

const firstSteps: OnBoardingProps[] = [
  {
    id: 'profile',
    title: 'Main Menu', 
    text: 'Here you can track your training progress and see how much money you have earned.',
    y: 'bottom',
  },
  {
    id: 'menu',
    title: 'Navigation', 
    text: 'Here you will find navigation to the main pages of our bot. Complete trainings, invite people, and accomplish tasks!',
    y: 'top'
  },
  {
    id: 'first-course',
    title: 'Your first course!', 
    text: 'Start by taking the basic course. Learn about Afrimex, investment opportunities in gold mining, and RWA technology.',
    y: 'bottom',
  },
];

