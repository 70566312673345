import SvgPlay from "@/assets/svg/SvgPlay";
import { cx } from "@/features/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";

export default function CustomPlayer({
  src = "",
  preview = "",
  fullScreen = false,
  className = ""
}: {
  src: string,
  preview?: string,
  fullScreen?: boolean,
  className?: string,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);

  const playHandler = () => {
    setIsPlaying(true);
    setTimeout(() => {
      if (ref?.current) {
        ref.current.play();
        if (fullScreen) {
          ref.current.requestFullscreen();
        }
      }
    }, 300);
  }

  return (
    <div className={cx("course-video", className)}>
      <AnimatePresence>
        {!isPlaying && (
          <motion.div
            className="course-video__preview"
            onClick={playHandler}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="course-video__play">
              <SvgPlay />
            </div>
            <img src={preview} alt="" />
          </motion.div>
        )}
      </AnimatePresence>
      <video src={src} controls ref={ref} preload="auto" />
    </div>
  );
}