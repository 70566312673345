import { useCallback } from 'react';

export const useScrollToElement = () => {
  const scrollToElement = useCallback(
    (element: HTMLElement, offset: number = 80) => {
      const rect = element.getBoundingClientRect();
      const container = document.getElementsByClassName('holder')[0] || document.body;
      const containerRect = container.getBoundingClientRect();

      // Положение элемента относительно контейнера
      const elementTop = rect.top - containerRect.top + container.scrollTop;
      const elementStyle = window.getComputedStyle(element);

      // Проверяем, находится ли элемент в видимой области
      const isElementFixed = elementStyle.position === 'fixed';

      if (!isElementFixed) {
        container.scrollTo({
          top: elementTop - offset,
          behavior: 'smooth',
        });
      }
    },
    []
  );

  return scrollToElement;
}