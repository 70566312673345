const SvgEducation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
  >
    <path
      fill="currentColor"
      d="M18.525 7.365a1.73 1.73 0 0 1-1.018 1.584l-2.024.916-1.131.509-3.212 1.459a3.87 3.87 0 0 1-3.268 0l-3.224-1.46-1.13-.508-1.131-.52v4.885a.56.56 0 0 1 .26.475v1.165a.56.56 0 0 1-.566.566h-.52a.56.56 0 0 1-.566-.566v-1.165a.56.56 0 0 1 .26-.475V9.028q0-.099.035-.192a1.733 1.733 0 0 1 .214-3.054l6.368-2.884a3.93 3.93 0 0 1 3.268 0l6.367 2.884c.622.28 1.021.901 1.018 1.583m-6.921 5.497a4.98 4.98 0 0 1-4.196 0l-3.89-1.753v1.447a2.86 2.86 0 0 0 1.108 2.251 8.04 8.04 0 0 0 9.76 0 2.84 2.84 0 0 0 1.097-2.25v-1.46l-.668.306z"
    ></path>
  </svg>
);

export default SvgEducation;
