const SvgSpain = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    fill="none"
    viewBox="0 0 24 18"
  >
    <g clipPath="url(#clip0_4269_115)">
      <rect
        width="23.154"
        height="16.538"
        x="0.513"
        y="0.654"
        fill="#F93939"
        rx="3.308"
      ></rect>
      <path
        fill="#F93939"
        d="M21.462.654H2.718A2.205 2.205 0 0 0 .513 2.859v12.128c0 1.218.987 2.205 2.205 2.205H21.46a2.205 2.205 0 0 0 2.206-2.205V2.86A2.205 2.205 0 0 0 21.46.654"
      ></path>
      <path
        fill="#FFDA2C"
        fillRule="evenodd"
        d="M.513 5.064h23.154v7.718H.513z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#D4AF2C"
        fillRule="evenodd"
        d="M10.436 7.514v2.779c0 .771-.741 1.389-1.654 1.389H6.577c-.91-.002-1.654-.623-1.654-1.392V7.512c0-.63.494-1.158 1.173-1.329.205-.573.836-.06 1.583-.06.752 0 1.379-.51 1.584.061.677.176 1.173.704 1.173 1.33"
        clipRule="evenodd"
      ></path>
      <path
        fill="#CBCBCB"
        fillRule="evenodd"
        d="M10.436 8.372h1.102v3.308h-1.102zm-6.615 0h1.102v3.308H3.821z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#1A47B8"
        fillRule="evenodd"
        d="M10.436 10.577h1.102v1.103h-1.102zm-6.615 0h1.102v1.103H3.821z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#D4AF2C"
        fillRule="evenodd"
        d="M10.436 7.27h1.102v1.102h-1.102zm-6.615 0h1.102v1.102H3.821z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#AF010D"
        fillRule="evenodd"
        d="M6.026 7.27h1.102v1.653H6.026zM8.23 9.473h1.102v1.654H8.231z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFDA2C"
        fillRule="evenodd"
        d="M6.026 9.474h1.102v1.654H6.026z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#AE6A3E"
        fillRule="evenodd"
        d="M8.23 7.27h1.103v1.653H8.231z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#AF010D"
        fillRule="evenodd"
        d="M7.128 7.27 6.026 6.166h3.307L8.231 7.269z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#D4AF2C"
        fillRule="evenodd"
        d="M7.128 5.064h1.103v1.103H7.128z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_4269_115">
        <rect
          width="23.154"
          height="16.538"
          x="0.513"
          y="0.654"
          fill="#fff"
          rx="3.308"
        ></rect>
      </clipPath>
    </defs>
  </svg>
);

export default SvgSpain;
