const SvgLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="20"
    fill="none"
    viewBox="0 0 15 20"
  >
    <path
      fill="currentColor"
      d="M13.125 7.5H12.5V5c0-2.757-2.242-5-5-5s-5 2.243-5 5v2.5h-.625C.842 7.5 0 8.34 0 9.375v8.75C0 19.159.842 20 1.875 20h11.25C14.158 20 15 19.16 15 18.125v-8.75A1.877 1.877 0 0 0 13.125 7.5M4.167 5A3.337 3.337 0 0 1 7.5 1.667 3.337 3.337 0 0 1 10.833 5v2.5H4.167zm4.166 8.935v1.898a.833.833 0 1 1-1.666 0v-1.898a1.66 1.66 0 0 1-.834-1.435c0-.92.748-1.667 1.667-1.667.92 0 1.667.748 1.667 1.667 0 .614-.338 1.146-.834 1.435"
    ></path>
  </svg>
);

export default SvgLock;
