import { APP_ROUTES } from "@/features/constants";
import { Navigate, Route, Routes } from "react-router-dom";
import Course from "@/pages/Course";
import Home from "@/pages/Home";
import Lesson from "@/pages/Course/Lesson";

export default function MainRoutes() {
  return (
    <Routes>
      <Route path={APP_ROUTES.HOME.INDEX} element={<Home />} />
      <Route path={APP_ROUTES.COURSE.INDEX}>
        <Route path={APP_ROUTES.COURSE.INDEX} index element={<Navigate to={APP_ROUTES.HOME.INDEX} />} />
        <Route path={APP_ROUTES.COURSE.DETAIL} index element={<Course />} />
        <Route path={APP_ROUTES.COURSE.LESSON} index element={<Lesson />} />
      </Route>
    </Routes>
  );
}
