const SvgBack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#fff"
      d="M18.126 8.375H5.793l5.362-5.346A1.632 1.632 0 1 0 8.848.721L.723 8.846a1.6 1.6 0 0 0-.342.537c-.162.395-.162.84 0 1.235q.119.302.342.536l8.125 8.125a1.625 1.625 0 0 0 2.307 0 1.626 1.626 0 0 0 0-2.308l-5.362-5.346h12.333a1.625 1.625 0 0 0 0-3.25"
    ></path>
  </svg>
);

export default SvgBack;
