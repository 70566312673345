import { createSlice } from "@reduxjs/toolkit";

interface OnBoardingsProps {
  [key: string]: {
    show?: boolean,
    done?: boolean,
    step?: number,
  }
}

const initialState = {
  onBoardings: {} as OnBoardingsProps,
}

export const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    updateOnBoardings: (state, action) => {
      state.onBoardings = { ...state.onBoardings, ...action.payload as OnBoardingsProps}
    },
    reset: () => initialState,
  },
});

export const {
  updateOnBoardings,
  reset,
} = toolsSlice.actions;
export default toolsSlice.reducer;
