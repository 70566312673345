import { BrowserRouter } from "react-router-dom";
import MainRoutes from "@/components/MainRoutes";
import LanguageSelector from "@/components/LanguageSelector";
import Intro from "@/components/Intro";
import Loader from "../Loader";
import { createPortal } from "react-dom";
// DEV
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { reset as resetTools } from "@/features/store/slices/toolsSlice";
import { reset as resetUser } from "@/features/store/slices/userSlice";

export default function MainApp() {

  /** DEV */
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetTools());
    dispatch(resetUser());
  }, []);


  return (
    <BrowserRouter>
      <MainRoutes />
      <LanguageSelector />
      <Intro />

      {createPortal(
        <Loader />,
        document.body,
      )}
    </BrowserRouter>
  );
}