import { RootState } from "@/features/store";
import { updateOnBoardings } from "@/features/store/slices/toolsSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SafeHTML from "@/components/SafeHTML";
import { AnimatePresence, motion } from "framer-motion";
import { cx } from "@/features/helpers";
import { useScrollToElement } from "@/features/hooks/useScrollToElement";

export default function OnBoarding({
  id,
  steps,
}: {
  id: string,
  steps: OnBoardingProps[],
}) {
  const [frameStyle, setFrameStyle] = useState<React.CSSProperties>({});
  const [boxStyle, setBoxStyle] = useState<React.CSSProperties>({});
  const dispatch = useDispatch();
  const onBoardingsList = useSelector((state: RootState) => state.tools.onBoardings);
  const scrollTo = useScrollToElement();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!onBoardingsList?.[id] || onBoardingsList?.[id]?.done === false) {
        dispatch(updateOnBoardings({
          [id]: {
            show: true,
            done: false,
            step: 0,
          }
        }))
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const skipBoard = () => {
    dispatch(updateOnBoardings({
      [id]: {
        show: false,
        done: true,
        step: 0,
      }
    }))
  }

  const goNext = () => {
    const currentStep = onBoardingsList?.[id]?.step || 0;
      
    let data = {
      ...onBoardingsList?.[id],
      step: currentStep + 1,
    };

    if (currentStep + 1 >= steps.length) {
      data = {
        step: 0,
        done: true,
        show: false,
      };
      document.getElementsByClassName('holder')[0].scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      const step = steps?.[currentStep + 1];
      const attachBox = document.getElementById(step.id);

      if (attachBox) {
        scrollTo(attachBox, 80);
      }
    }


    dispatch(updateOnBoardings({
      [id]: data
    }));
  }

  const calculatePosition = () => {
    const index = onBoardingsList?.[id]?.step || 0;
    const step = steps?.[index];

    const attachBox = document.getElementById(step.id);
    const xPos = attachBox?.getBoundingClientRect()?.x || 0;
    const yPos = attachBox?.getBoundingClientRect()?.y || 0;
    const boxHeight = attachBox?.getBoundingClientRect()?.height || 0;
    const boxWidth = attachBox?.getBoundingClientRect()?.width || 0;

    const obFrameStyle: React.CSSProperties = {
      'left': `${xPos - 8}px`,
      'top': `${yPos - 8}px`,
      'height': `${boxHeight + 16}px`,
      'width': `${boxWidth + 16}px`,
      'zIndex': 100,
    };

    const obBoxStyle: React.CSSProperties = {
      left: `${xPos}px`,
      width: `calc(100% - ${xPos * 2}px)`,
      top: 'auto',
      bottom: 'auto',
      zIndex: 101,
    };

    if (step.y === 'bottom') {
      obBoxStyle.top = `${(yPos + boxHeight + 16)}px`;
    } else if (step.y === 'top') {
      obBoxStyle.bottom = `${(boxHeight + 36)}px`;
    }

    setFrameStyle(obFrameStyle);
    setBoxStyle(obBoxStyle);
  }

  // Scroll
  useEffect(() => {
    const scrollBody = document.getElementsByClassName('holder')[0];
    calculatePosition();
    // Listeners
    scrollBody.addEventListener('scroll', calculatePosition, false);
    window.addEventListener('resize', calculatePosition, false);
    return () => {
      scrollBody.removeEventListener('scroll', calculatePosition, false);
      window.removeEventListener('resize', calculatePosition, false);
    }
  }, [onBoardingsList?.[id]?.step]);

  return (
    <AnimatePresence mode="sync">
      {onBoardingsList?.[id]?.show ? steps.map(({ id: boxId, title, text, y }, index) => {
        return ((onBoardingsList?.[id]?.step || 0) === index) && (
          <motion.div
            className='onboarding'
            id={`onboarding:${id}`}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.01,
            }}
            style={{
              position: 'static',
              zIndex: 100 + index,
            }}
            key={`onboarding:${id}-${boxId}`}
          >
            <motion.div
              className="onboarding__frame"
              style={frameStyle}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            />
            <motion.div
              className={cx('onboarding__box', y === 'top' ? '--top' : '--bottom')}
              style={boxStyle}
              initial={{
                y: y === 'bottom' ? '20%' : '-20%',
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
              }}
              exit={{
                y: y === 'bottom' ? '-20%' : '20%',
                opacity: 0,
              }}
            >
              <div className="onboarding__count">
                { index + 1 } / { steps.length } Tips
              </div>
              <div className="onboarding__title">
                { title }
              </div>
              <div className="onboarding__text">
                <SafeHTML html={text} />
              </div>
              <div className="onboarding__buttons">
                <button className="default-button" onClick={goNext}>
                  Next
                </button>
                <button className="default-button --link" onClick={skipBoard}>
                  Skip
                </button>
              </div>
            </motion.div>
          </motion.div>
        )
      }) : null}
    </AnimatePresence>
  ) 
}

export interface OnBoardingProps {
  id: string,
  title: string,
  text: string,
  y: 'top' | 'bottom',
}
