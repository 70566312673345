import SafeHTML from "@/components/SafeHTML";
import { TextBlock } from "../../types";
import { cx } from "@/features/helpers";
import { motion } from "framer-motion";
import { forwardRef } from "react";
import Button from "@/components/Button";

const ReadText = forwardRef<HTMLDivElement, TextBlock>(({
  index = 0,
  current = 0,
  title,
  text,
  nextHandler
}: TextBlock, ref) => {
  const isDone = index < current;
  return (
    <div className="lesson__block" ref={ref}>
      <div className={cx('lesson__block--line', isDone && '--active')} />
      <motion.div
        className="lesson__block--index"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.2 }}
      >
        { index + 1 }
      </motion.div>
      <div className="lesson__block--title">
        { title }
      </div>
      <div className="lesson__block--text --light">
        <SafeHTML html={text} />
      </div>
      {!isDone && (
        <div className="lesson__block--button">
          <Button onClick={nextHandler} className="default-button w-100" haptic={false}>
            I read it
          </Button>
        </div>
      )}
    </div>
  );
});

export default ReadText;
