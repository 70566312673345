import SvgFrance from "@/assets/svg/flags/SvgFrance";
import SvgRussia from "@/assets/svg/flags/SvgRussia";
import SvgSpain from "@/assets/svg/flags/SvgSpain";
import SvgUsa from "@/assets/svg/flags/SvgUsa";

export const APP_ROUTES = {
  HOME: {
    INDEX: '/',
  },
  COURSE: {
    INDEX: '/course',
    DETAIL: '/course/:courseId',
    LESSON: '/course/:courseId/:lessonId',
  },
  TEAM: {
    INDEX: '/team',
  },
  TASKS: {
    INDEX: '/tasks',
  },
  WALLET: {
    INDEX: '/wallet',
  }
}

export const LANGUAGES = [
  { key: 'en', name: 'English', icon: <SvgUsa /> },
  { key: 'es', name: 'Spanish', icon: <SvgSpain /> },
  { key: 'fr', name: 'French', icon: <SvgFrance /> },
  { key: 'ru', name: 'Russian', icon: <SvgRussia /> },
];
