const SvgUsa = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="17"
    fill="none"
    viewBox="0 0 24 17"
  >
    <g clipPath="url(#clip0_4269_108)">
      <rect
        width="23.154"
        height="16.538"
        x="0.513"
        y="0.09"
        fill="#fff"
        rx="3.308"
      ></rect>
      <path
        fill="#1A47B8"
        fillRule="evenodd"
        d="M.513.09h9.923v7.718H.513z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F93939"
        fillRule="evenodd"
        d="M10.436.09v1.102h13.23V.09zm0 2.205v1.102h13.23V2.295zm0 2.205v1.103h13.23V4.5zm0 2.205v1.103h13.23V6.705zM.513 8.91v1.103h23.154V8.91zm0 2.205v1.103h23.154v-1.103zm0 2.206v1.102h23.154v-1.102zm0 2.205v1.102h23.154v-1.102z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M1.615 1.192v1.103h1.103V1.192zm2.206 0v1.103h1.102V1.192zm2.205 0v1.103h1.102V1.192zm2.205 0v1.103h1.102V1.192zM7.128 2.295v1.102h1.103V2.295zm-2.205 0v1.102h1.103V2.295zm-2.205 0v1.102h1.103V2.295zM1.615 3.397V4.5h1.103V3.397zm2.206 0V4.5h1.102V3.397zm2.205 0V4.5h1.102V3.397zm2.205 0V4.5h1.102V3.397zM1.615 5.603v1.102h1.103V5.603zm2.206 0v1.102h1.102V5.603zm2.205 0v1.102h1.102V5.603zm2.205 0v1.102h1.102V5.603zM7.128 4.5v1.103h1.103V4.5zm-2.205 0v1.103h1.103V4.5zm-2.205 0v1.103h1.103V4.5z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_4269_108">
        <rect
          width="23.154"
          height="16.538"
          x="0.513"
          y="0.09"
          fill="#fff"
          rx="3.308"
        ></rect>
      </clipPath>
    </defs>
  </svg>
);

export default SvgUsa;
