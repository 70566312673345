import DOMPurify from 'dompurify';
import { cloneElement } from 'react';

export default function SafeHTML({
  html,
  children = null
}: {
  html: string,
  children?: React.ReactElement | null,
}) {
  const sanitizedHTML = DOMPurify.sanitize(html, { ADD_TAGS: ['iframe'] });
  if (children === null) return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
  return cloneElement(children, { dangerouslySetInnerHTML: { __html: sanitizedHTML } });
}
