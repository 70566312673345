const SvgBook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="17"
    fill="none"
    viewBox="0 0 14 17"
  >
    <path
      fill="#14161B"
      d="M12.514 15.926H3.372c-2.117 0-2.855-1.122-2.855-2.149s.738-2.149 2.855-2.149h9.142c.353 0 .641.289.641.642a.643.643 0 0 1-.641.641H3.372c-.705 0-1.572.16-1.572.866s.867.866 1.572.866h9.142c.353 0 .641.29.641.642a.643.643 0 0 1-.641.641"
    ></path>
    <path
      fill="#14161B"
      d="m4.871 12.292-3.143.393h-.393L.942 2.47l1.179-1.178h10.216v11.001z"
    ></path>
    <path
      fill="#14161B"
      d="M1.224 13.62a.643.643 0 0 1-.641-.64V2.65c0-1.154.898-2.084 2.02-2.084h9.88c.353 0 .641.289.641.642v10.937a.643.643 0 0 1-.641.642.643.643 0 0 1-.642-.642V1.85H2.604c-.385 0-.738.385-.738.802v10.328a.643.643 0 0 1-.642.642"
    ></path>
    <path
      fill="#14161B"
      d="M12.513 16.156c-.77 0-1.25-.866-1.25-2.18 0-1.316.512-2.182 1.25-2.182.353 0 .642.289.642.642 0 .288-.193.545-.45.609-.063.128-.16.45-.16.93s.097.802.16.93c.257.097.45.321.45.61a.643.643 0 0 1-.642.641"
    ></path>
  </svg>
);

export default SvgBook;
