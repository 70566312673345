import { CSSProperties, useEffect, useRef } from 'react';

interface ResizableTypes {
  listen?: boolean,
  children?: React.ReactNode,
  className?: string,
  holderStyle?: CSSProperties,
  transformerStyle?: CSSProperties,
}

export default function Resizable({
  listen = false,
  children,
  className,
  holderStyle,
  transformerStyle,
}: ResizableTypes) {
  const transformer = useRef<HTMLDivElement>(null);
  const holder = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (holder.current && transformer.current) {
        const hold = holder.current.offsetWidth;
        const transform = transformer.current.offsetWidth;
        transformer.current.style.scale = `${hold / transform}`;
      }
    }, 20);
    return () => clearTimeout(timeout);
  }, [listen, children]);

  return (
    <div
      ref={holder}
      style={{
        ...holderStyle,
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
      }}
    >
      <div style={{ ...transformerStyle, transformOrigin: 'left center', flexGrow: 1 }} ref={transformer} className={className}>
        { children }
      </div>
    </div>
  );
}
