import { LANGUAGES } from "@/features/constants";
import { RootState } from "@/features/store";
import { setLanguage, toggleIntro } from "@/features/store/slices/userSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function LanguageSelector() {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state: RootState) => (state.user.language));
  const [lang, setLang] = useState<string | null>(null);

  const setLanguageHandler = () => {
    dispatch(setLanguage(lang));
    dispatch(toggleIntro(true));
  };

  return !selectedLanguage ? (
    <div className="language-selector">
      <div className="language-selector__content">
        <div className="language-selector__title">
          What language are you
          <br />
          want to study?
        </div>
        <div className="language-selector__list">
          {LANGUAGES.map(({ key, name, icon }) => (
            <div className="language" onClick={() => setLang(key)} key={`lang-${key}`}>
              <input type="radio" name="language" id={`lang-${key}`} />
              <label htmlFor={`lang-${key}`}>
                { icon }
                <span>
                  { name }
                </span>
              </label>
            </div>
          ))}
        </div>
        <button className="default-button w-100 --lg" disabled={!lang} onClick={() => setLanguageHandler()}>
          Continue
        </button>
        <div className="language-selector__terms">
          By using it you confirm that you have read and
          <br />
          agree to our terms of service and privacy policy
        </div>
      </div>
    </div>
  ) : null;
}
