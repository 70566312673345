import AppHolder from "@/components/AppHolder";
import PageTitle from "@/components/PageTitle";
import { useEffect, useRef, useState } from "react";
import { LessonBlock } from "./types";
import WatchVideo from "./components/WatchVideo";
import DownloadFiles from "./components/DownloadFiles";
import VisitLinks from "./components/VisitLinks";
import ReadText from "./components/ReadText";
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";
import RAYS_IMAGE from '@/assets/img/rays.png';
import ConfettiExplosion from "react-confetti-explosion";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { APP_ROUTES } from "@/features/constants";
import { RemoveScroll } from "react-remove-scroll";
import SvgCheck from "@/assets/svg/SvgCheck";
import { useHapticDelays } from "@/features/hooks/useHapticDelays";

export default function Lesson() {
  const location = useLocation();
  return (
    <AppHolder hideMenu>
      <Content key={location.pathname} />
    </AppHolder>
  );
}


function Content() {
  const { courseId } = useParams();
  const [currentLesson, setCurrentLesson] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const blocksRef = useRef<(HTMLDivElement | null)[]>([]);
  const playNext = useHapticDelays([
    0, 200,
  ])
  const playWin = useHapticDelays([
    0, 200, 400, 600, 1000, 1200
  ])

  const nextHandler = () => {
    if (currentLesson < LESSONS_PREVIEW.length - 1) {
      playNext();
      setCurrentLesson((current) => {
        return (current + 1);
      });
    } else {
      playWin();
      setIsEnd(true);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentLesson > 0) {
        blocksRef?.current?.[currentLesson]?.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [currentLesson, blocksRef?.current?.[currentLesson]]);

  return (
    <>
      <PageTitle>
        Lesson { currentLesson + 1 }/{LESSONS_PREVIEW.length}
      </PageTitle>
      <div className="lesson">
        <div className="lesson__title">
          A Comprehensive Introduction to Gold Mining
        </div>
        <div className="lesson__list">
          {LESSONS_PREVIEW.map((lesson, index) => {
            lesson.index = index;
            lesson.current = currentLesson;
            lesson.nextHandler = nextHandler;

            if (index > currentLesson) return null;

            switch(lesson.type) {
              case 'text': 
                return <ReadText {...lesson} ref={(el) => (blocksRef.current[index] = el)} />
              case 'files':
                return <DownloadFiles {...lesson} ref={(el) => (blocksRef.current[index] = el)} />
              case 'links':
                return <VisitLinks {...lesson} ref={(el) => (blocksRef.current[index] = el)} />
              case 'video':
                return <WatchVideo {...lesson} ref={(el) => (blocksRef.current[index] = el)} />
            }
          })}
        </div>
      </div>
      {createPortal(
        <AnimatePresence>
          {isEnd && (
            <RemoveScroll>
              <motion.div
                className="lesson-reward"
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
              >
                <ConfettiExplosion
                  zIndex={90}
                  style={{
                    position: 'absolute',
                    left: 0,
                  }}
                />
                <ConfettiExplosion
                  zIndex={90}
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                />
                <div className="lesson-reward__info">
                  <div className="lesson-reward__title">
                    Lesson is finished!
                  </div>
                  <div className="lesson-reward__subtitle">
                    Your reward:
                  </div>
                  <div className="lesson-reward__value">
                    <div className="lesson-reward__image">
                      <img src={RAYS_IMAGE} alt="" />
                    </div>
                    $10
                  </div>
                  <div className="lesson-reward__subtitle">
                    Your progress:
                  </div>
                  <div className="lesson-reward__progress">
                    <span className="--done">
                      <SvgCheck />
                    </span>
                    <span className="--pending">
                      2
                    </span>
                    <span>
                      3
                    </span>
                    <span>
                      4
                    </span>
                    <span>
                      5
                    </span>
                  </div>
                </div>
                <NavLink to={`${APP_ROUTES.COURSE.INDEX}/${courseId}/lesson-2`} className="default-button --lg">
                  Next lesson
                </NavLink>
              </motion.div>
            </RemoveScroll>
          )}
        </AnimatePresence>,
        document.body,
      )}
    </>
  );
}

const LESSONS_PREVIEW: LessonBlock[] = [
  {
    type: 'video',
    title: 'Watch video',
    description: 'Some description under the video',
    src: '/media/intro/1.mp4',
    preview: '/img/preview/course-video.png',
  },
  {
    type: 'files',
    title: 'Download all the documents for review, if necessary',
    description: 'Some description under the video',
    files: [
      {
        name: 'Afrimex Marketing 2.0',
        ext: 'pdf',
        src: 'https://google.com/',
      },
      {
        name: 'Office video inviting',
        ext: 'mp4',
        src: 'https://google.com/',
      },
      {
        name: 'Schedule of webinars',
        ext: 'doc',
        src: 'https://google.com/',
      },
    ],
  },
  {
    type: 'text',
    title: 'Read this text',
    text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla adipisci quod optio repellat, ex maxime nostrum! Laudantium voluptatem et, omnis veritatis eaque voluptatum assumenda obcaecati ratione dignissimos ipsam tempore sunt!',
  },
  {
    type: 'links',
    title: 'Visit all the links for review, if necessary',
    description: 'Some description under the video',
    links: [
      {
        name: 'Afrimex Marketing 2.0',
        url: 'https://google.com/',
      },
      {
        name: 'Office video inviting',
        url: 'https://google.com/',
      },
      {
        name: 'Schedule of webinars',
        url: 'https://google.com/',
      },
    ],
  },
];
