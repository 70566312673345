import SvgBack from "@/assets/svg/SvgBack";
import useOnScreen from "@/features/hooks/useOnScreen";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function PageTitle({
  children,
  back = true,
}: {
  children?: React.ReactNode,
  back?: boolean,
}) {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    const clickHandler = () => navigate(-1);
    if (!isVisible) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(clickHandler);
    } else {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(clickHandler);
    }
  }, [isVisible]);

  return (
    <div className="page-title" ref={ref}>
      <div>
        {back && (
          <button
            className="back-button"
            onClick={() => navigate(-1)}
          >
            <SvgBack />
          </button>
        )}
      </div>
      { children }
    </div>
  );
}