const SvgTasks = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      d="M13.384 2.002h-6.75c-2.933 0-4.68 1.748-4.68 4.68v6.75c0 2.933 1.747 4.681 4.68 4.681h6.75c2.932 0 4.68-1.748 4.68-4.68v-6.75c0-2.933-1.748-4.681-4.68-4.681m-5.01 10.392L6.56 14.206a.6.6 0 0 1-.427.177.58.58 0 0 1-.427-.177l-.604-.604a.593.593 0 0 1 0-.854.61.61 0 0 1 .854 0l.177.177L7.52 11.54a.61.61 0 0 1 .853 0 .61.61 0 0 1 0 .854m0-5.64L6.56 8.568a.6.6 0 0 1-.427.177.58.58 0 0 1-.427-.177l-.604-.604a.593.593 0 0 1 0-.854.61.61 0 0 1 .854 0l.177.177L7.52 5.901a.61.61 0 0 1 .853 0 .61.61 0 0 1 0 .854m6.114 7.025h-4.23a.61.61 0 0 1-.604-.604c0-.33.274-.604.604-.604h4.23a.603.603 0 1 1 0 1.208m0-5.639h-4.23a.61.61 0 0 1-.604-.604c0-.33.274-.604.604-.604h4.23a.603.603 0 1 1 0 1.208"
    ></path>
  </svg>
);

export default SvgTasks;
