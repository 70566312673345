import { APP_ROUTES } from "@/features/constants";
import { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import SvgClock from "@/assets/svg/SvgClock";
import SvgGreenCheck from "@/assets/svg/SvgGreenCheck";
import SvgLock from "@/assets/svg/SvgLock";
import SvgPlay from "@/assets/svg/SvgPlay";
import SvgSandClock from "@/assets/svg/SvgSandClock";
import AppHolder from "@/components/AppHolder";
import CustomPlayer from "@/components/CustomPlayer";
import PageTitle from "@/components/PageTitle";

export default function Course() {
  return (
    <AppHolder>
      <PageTitle>
        Course Details
      </PageTitle>
      <Content key={location.pathname} />
    </AppHolder>
  );
}

function Content() {
  const navigate = useNavigate();
  const { courseId } = useParams();
  
  useEffect(() => {
    if (!courseId) {
      navigate(APP_ROUTES.HOME.INDEX);
    }
  }, [courseId]);

  return (
    <>
      <div className="course-detail">
        <div className="course-detail__name">
          What is Afrimex? How to start with us?
        </div>
        <CustomPlayer
          src={"/media/intro/1.mp4"}
          preview="/img/preview/course-video.png"
          fullScreen
        />
        <div className="course-detail__author">
          <div className="course-detail__author--avatar">
            <img src="/img/preview/author-1.png" alt="" />
          </div>
          <div className="course-detail__author--info">
            <span>Kamil Mundo</span>
            <small>Chief Strategy Officer (CSO)</small>
          </div>
          <div className="course-detail__author--time">
            <SvgClock />
            ~1h 20min
          </div>
        </div>
        <div className="course-detail__badges">
          <span className="--count">
            6 lessons
          </span>
          <span className="--marketing">
            Marketing
          </span>
          <span className="--investments">
            Investments
          </span>
        </div>
        <div className="course-detail__shortdesc">
        In this course, I will show you a step-by-step, daily process of how to start making money from investing in gold with Afrimex!
        </div>
        <div className="course-detail__list">
          <NavLink to={`${APP_ROUTES.COURSE.INDEX}/${courseId}/lesson-1`} className="course-detail__lesson --done">
            <div className="course-detail__lesson--preview">
              <SvgPlay />
              <img src="/img/preview/course-video.png" alt="" />
            </div>
            <div className="course-detail__lesson--info">
              <div className="course-detail__lesson--name">
                A Comprehensive Introduction to Gold Mining
                <SvgGreenCheck />
              </div>
              <div className="course-detail__lesson--duration">
                <small>
                  01h 41min
                </small>
                <span>+$10</span>
              </div>
            </div>
          </NavLink>
          <NavLink to={`${APP_ROUTES.COURSE.INDEX}/${courseId}/lesson-1`} className="course-detail__lesson --progress">
            <div className="course-detail__lesson--preview">
              <SvgPlay />
              <img src="/img/preview/course-video.png" alt="" />
            </div>
            <div className="course-detail__lesson--info">
              <div className="course-detail__lesson--name">
                A Comprehensive Introduction to Gold Mining
                <SvgSandClock />
              </div>
              <div className="course-detail__lesson--duration">
                <small>
                  01h 41min
                </small>
                <span>+$10</span>
              </div>
            </div>
          </NavLink>
          <NavLink to="" className="course-detail__lesson --unavailable">
            <div className="course-detail__lesson--preview">
              <SvgPlay />
              <img src="/img/preview/course-video.png" alt="" />
            </div>
            <div className="course-detail__lesson--info">
              <div className="course-detail__lesson--name">
                A Comprehensive Introduction to Gold Mining
                <SvgLock />
              </div>
              <div className="course-detail__lesson--duration">
                <small>
                  01h 41min
                </small>
                <span>+$10</span>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
}
