/**
 * Играет мелодию вибрацией, используя Haptic FeedBack.
 * @param delays - массив задержек в миллисекундах.
 * @returns 
 */

export const useHapticDelays = (delays: number[]) => {
  const play = () => {
    delays.forEach((delay) => {
      setTimeout(() => {
        window.Telegram.WebApp.HapticFeedback.selectionChanged();
        console.log(delay);
      }, delay);
    });
  }
  return play;
}