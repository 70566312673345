const SvgGreenCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#4ED542"
      fillRule="evenodd"
      d="M7 0a7 7 0 0 0-7 7v9.333a7 7 0 0 0 7 7h9.333a7 7 0 0 0 7-7V7a7 7 0 0 0-7-7zm4.23 15.867c-.584.622-1.46.622-2.043 0L6.271 12.6a1.574 1.574 0 0 1 0-2.178c.583-.622 1.458-.622 2.041 0l1.896 2.022 4.813-4.977c.583-.623 1.458-.623 2.041 0a1.574 1.574 0 0 1 0 2.177z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgGreenCheck;
