const SvgWallet = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <g fill="currentColor">
      <path d="M8.611 4.291c0-.936.739-1.698 1.646-1.698h.57l-.172-.493a.66.66 0 0 0-.62-.45.6.6 0 0 0-.225.042L4.054 3.853a.685.685 0 0 0-.394.872l.305.857H8.61z"></path>
      <path d="M10.19 3.616a.676.676 0 0 0-.673.678v1.288h5.896V4.294a.676.676 0 0 0-.674-.678zM17.051 15.12h-2.308a2.48 2.48 0 0 1-2.472-2.483 2.48 2.48 0 0 1 2.472-2.482h2.308V7.888c0-.73-.59-1.324-1.319-1.324H2.973c-.728 0-1.318.593-1.318 1.324v9.473c0 .732.59 1.324 1.318 1.324h12.76c.728 0 1.318-.592 1.318-1.324z"></path>
      <path d="M18.198 11.15h-3.276a1.476 1.476 0 0 0 0 2.948h3.276c.27 0 .49-.22.49-.49v-1.966c0-.27-.22-.491-.49-.491m-3.276 1.966a.491.491 0 1 1 0-.983.491.491 0 0 1 0 .983"></path>
    </g>
  </svg>
);

export default SvgWallet;
