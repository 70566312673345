const SvgFrance = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="17"
    fill="none"
    viewBox="0 0 24 17"
  >
    <g clipPath="url(#clip0_4269_119)">
      <rect
        width="23.154"
        height="16.538"
        x="0.513"
        y="0.218"
        fill="#fff"
        rx="3.308"
      ></rect>
      <path
        fill="#F93939"
        fillRule="evenodd"
        d="M15.949.218h7.718v16.538h-7.718z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#1A47B8"
        fillRule="evenodd"
        d="M.513.218H8.23v16.538H.513z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_4269_119">
        <rect
          width="23.154"
          height="16.538"
          x="0.513"
          y="0.218"
          fill="#fff"
          rx="3.308"
        ></rect>
      </clipPath>
    </defs>
  </svg>
);

export default SvgFrance;
