import Button from "@/components/Button";
import { FilesBlock } from "../../types";
import { cx } from "@/features/helpers";
import { motion } from "framer-motion";
import { forwardRef } from "react";

const DownloadFiles = forwardRef<HTMLDivElement, FilesBlock>(({
  index = 0,
  current = 0,
  title,
  description,
  // files,
  nextHandler
}: FilesBlock, ref) => {
  const isDone = index < current;
  return (
    <div className="lesson__block" ref={ref}>
      <div className={cx('lesson__block--line', isDone && '--active')} />
      <motion.div
        className="lesson__block--index"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.2 }}
      >
        { index + 1 }
      </motion.div>
      <div className="lesson__block--title">
        { title }
      </div>
      Files here
      <div className="lesson__block--text">
        { description }
      </div>
      {!isDone && (
        <div className="lesson__block--button">
          <Button onClick={nextHandler} className="default-button w-100" haptic={false}>
            I downloaded it
          </Button>
        </div>
      )}
    </div>
  );
});

export default DownloadFiles;
