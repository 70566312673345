const SvgSandClock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="23"
    fill="none"
    viewBox="0 0 15 23"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.702 12.826a2.125 2.125 0 0 0 0-3.088l-.121-.116C2.307 7.452.55 5.777.02 2.265-.165 1.026.896 0 2.173 0h10.323c1.276 0 2.337 1.026 2.15 2.265-.53 3.512-2.286 5.187-4.559 7.357l-.121.116a2.125 2.125 0 0 0 0 3.088l.121.116c2.273 2.17 4.03 3.845 4.559 7.357.187 1.239-.874 2.265-2.15 2.265H2.173c-1.278 0-2.339-1.026-2.151-2.265.529-3.512 2.285-5.187 4.559-7.357zm.77 1.915-.008.008c-1.732 1.549-3.021 2.702-3.19 5.554a.53.53 0 0 0 .19.438c.097.081.223.13.36.13h9.018c.162 0 .309-.068.41-.177a.53.53 0 0 0 .14-.39c-.169-2.853-1.458-4.006-3.19-5.555-.458-.41-.947-.848-1.455-1.352a.59.59 0 0 0-.828 0c-.505.501-.991.937-1.447 1.345"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgSandClock;
