const SvgClock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="16"
    fill="none"
    viewBox="0 0 13 16"
  >
    <path
      fill="currentColor"
      d="M6.166 2.82a6.12 6.12 0 0 0-6.11 6.11c0 3.37 2.74 6.11 6.11 6.11s6.111-2.74 6.111-6.11-2.741-6.11-6.11-6.11m0 10.693A4.59 4.59 0 0 1 1.583 8.93a4.59 4.59 0 0 1 4.583-4.583A4.59 4.59 0 0 1 10.75 8.93a4.59 4.59 0 0 1-4.583 4.583"
    ></path>
    <path
      fill="currentColor"
      d="M5.402 5.875H6.93v3.819H5.402zM3.875.528h4.583v1.527H3.875zM11.737 4.887l-1.528-1.528 1.08-1.08 1.528 1.528z"
    ></path>
  </svg>
);

export default SvgClock;
