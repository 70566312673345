import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import toolsReducer from '@/features/store/slices/toolsSlice';
import userReducer from '@/features/store/slices/userSlice';

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ['api', 'fileApi'],
};

const rootReducer = combineReducers({
  tools: toolsReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
