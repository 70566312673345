import { NavLink, useLocation } from "react-router-dom";
import { APP_ROUTES } from "@/features/constants";
import { useEffect } from "react";
import SvgEducation from "@/assets/svg/menu/SvgEducation";
import SvgTasks from "@/assets/svg/menu/SvgTasks";
import SvgWallet from "@/assets/svg/menu/SvgWallet";
import SvgTeam from "@/assets/svg/menu/SvgTeam";
import { cx } from "@/features/helpers";

const mobilePlatforms = ['ios', 'android']

export default function AppHolder({
  children,
  hideMenu = false,
}: {
  children: React.ReactNode,
  hideMenu?: boolean,
}) {
  const location = useLocation();

  useEffect(() => {
    if (!!window.Telegram.WebApp.initData) {
      window.Telegram.WebApp.headerColor = '#121A22';
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.lockOrientation();
      window.Telegram.WebApp.expand();

      if (mobilePlatforms.includes(window.Telegram.WebApp.platform)) {
        window.Telegram.WebApp.requestFullscreen();
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      window.Telegram.WebApp.BackButton.hide();
    }
    window.Telegram.WebApp.HapticFeedback.selectionChanged();
  }, [location.pathname]);
  
  return (
    <>
      <div className="holder">
        <div className={cx('holder__content', hideMenu && '--no-menu')}>
          { children }
        </div>
        {!hideMenu && (
          <div className="menu" id="menu">
            <NavLink to={APP_ROUTES.HOME.INDEX}>
              <SvgEducation />
              <span>
                Courses
              </span>
            </NavLink>
            <NavLink to={APP_ROUTES.TEAM.INDEX}>
              <SvgTeam />
              <span>
                Team
              </span>
            </NavLink>
            <NavLink to={APP_ROUTES.TASKS.INDEX}>
              <SvgTasks />
              <span>
                Tasks
              </span>
            </NavLink>
            <NavLink to={APP_ROUTES.WALLET.INDEX}>
              <SvgWallet />
              <span>
                Wallet
              </span>
            </NavLink>
          </div>
        )}
      </div>
    </>
  );
}
